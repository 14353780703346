import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import ImageHero from "../components/shared/ImageHero";

const pageDescription =
  "Questions. Frequently asked ones. Plus our answers. That's how FAQs work. If you can't find what you're looking for, you can always send us an email with your enquiry.";
const pagesBreadcrumb = [
  {
    name: "FAQs",
    to: "/faqs",
  },
];
const faqs = [
  {
    question: "How much does a loan cost?",
    answer:
      "The full cost of the loan is calculated upfront. When you're happy click the 'Apply Now' button from the personal or business loan application page in The Dashboard.",
  },
  {
    question: "Do I qualify for a loan?",
    answer:
      "You have to be over 18 years of age, a resident and employed in South Africa. In addition, you must have an active bank account, an email address, and a contact number.",
  },
  {
    question: "When will I receive my loan funds?",
    answer:
      "An assessment to determine whether you qualify for a loan will be done and we will provide you with feedback within 24 hours.",
  },
  {
    question: "What is The Dashboard?",
    answer:
      "The Dashboard, located on the website, is a cloud-based web application that gives you private and secure access to an all-in-one location to easily manage all your loans, claims, insurances, and more.",
  },
  {
    question: "Is The Dashboard free?",
    answer:
      "The Dashboard is provided as a free tool for all our clients to view and manage their financial relationship with us.",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const FAQSPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Help yourself"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default FAQSPage;

export const Head: HeadFC = () => (
  <CustomHead title="FAQs" description={pageDescription} />
);
