import * as React from "react";
import Breadcrumb from "./Breadcrumb";

export interface ImageHeroProps {
  title: string;
  description: string;
  pages?: { name: string; to: string }[];
}

const ImageHero = ({ title, description, pages }: ImageHeroProps) => {
  return (
    <div className="relative min-h-[400px] bg-indigo-800">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
          alt=""
        />
        <div
          className="absolute inset-0 bg-indigo-800 mix-blend-multiply"
          aria-hidden="true"
        />
      </div>
      <div className="relative mx-auto max-w-7xl py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
        {pages && pages.length > 0 && (
          <div className="mb-16">
            <Breadcrumb pages={pages} />
          </div>
        )}
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {title}
        </h1>
        <p className="mt-6 max-w-3xl text-xl text-indigo-100">{description}</p>
      </div>
    </div>
  );
};

export default ImageHero;
