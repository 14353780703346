import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";

export interface CustomHeadProps {
  title: string;
  description?: string;
}

export const CustomHead = ({ title, description }: CustomHeadProps) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const siteMetadata = data.site.siteMetadata;

  return (
    <>
      <title>
        {siteMetadata.title} | {title}
      </title>
      <meta
        name="description"
        content={description ?? siteMetadata.description}
      />
    </>
  );
};
